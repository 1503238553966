html, body, #root, #root > div.container-fluid, #root > div.container-fluid > div.row{
  height: 100%;
}

div.page-content {
  height: 100%;
}

img.home {
  width: 150px;
}

button.logout-button {
  float:right;
}

div.configurator-item {
  cursor: pointer;
}

div.clear {
  clear: left;
}

div.configurator-item div.configurator-target-content {
  border-radius:5px;
  padding: 5px 10px 5px 5px;
}

div.configurator-target-content.other-tariff {
  background-color: #f6f8ff;
}

div.configurator-target-content.broker-tariff {
  background-color: #d8fdde;
}

div.configurator-item div.configurator-item-name span.branch-content {
  border: #93d3ff solid 1px;
  border-radius: 5px;
  background: #e2f2ff;
  margin-right: 3px;
  line-height: 1.7em;
}

div.configurator-item div.configurator-item-name span.default-content:not(:last-child):after,
span.content-to-delete div.configurator-item-name span.default-content:not(:last-child):after {
  content: ", ";
}

div.tariff-mulitiple-wrapper {
  display: inline-table;
}

span.tariff-single {

}

span.tariff-multiple {
  display:block;
}

span.configurator-target-content-text {
  color: blue;
}

span.configurator-target-content-text.obsolete-tariff,
span.configurator-target-content-text.obsolete-tariff,
div.tariff-mulitiple-wrapper.obsolete-tariff,
span.tariff-single.obsolete-tariff,
span.tariff-mulitiple.obsolete-tariff
{
  text-decoration: line-through;
  color: lightgray;
}

div.target-content {
  padding: 10px;
  border: 1px lightgrey solid;
  margin-bottom: 20px;
  border-radius: 5px;
}

.tariff-item {
  font-weight: bold;
  font-size: large;
}

.tariff-configuration-item {
  font-weight: bold;
}

div.construction-type {
  float: left;
  font-size: x-small;
  border: solid 1px grey;
  background-color: lightgrey;
  margin: 0px 4px 4px 4px;
  padding: 3px;
  border-radius: 3px;
}

div.hidden {
  display: none;
}

span.method-remark {
  font-size: small;
  font-style: italic;
}

/* Style navigation menu links */
.node-content {
  color: black;
  padding: 7px 7px;
  text-decoration: none;
  font-size: 17px;
}

/* Style the hamburger menu */
.node-content.icon {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

/* Style the active link (or home/logo) */
.active {

}

.cursor-pointer {
  cursor: pointer;
}

span.page-tour {
  cursor: pointer;
  margin-left: 1rem;
}

span.crate-policy-content-item {
  margin-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  color: #959595;
}

span.crate-policy-content-item.active {
  font-size: larger;
  font-weight: bold;
  color: #000000;
}

.appvers-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  -webkit-transition: all .15s linear;
  transition: all .15s linear;
  border: 2px dashed #d9d9d9;
  border-radius: 4px;
}

.appvers-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #d9d9d9;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.appvers-preview {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  border-bottom: 1px solid #ECECEC;
  box-sizing: border-box;
}

div.input-group-prepend  input.form-control {
  width: auto;
  height: auto;
}

.search-label{
  float:right;
}

.input-group-sm > .form-control input.configurator-target-discount {
  width: 4em;
}

input.configurator-target-manual-tariff {
  width: 10rem;
  float: right;
}

div.indexed-wrapper {
  width: 10.25rem; /*inner switch button has margins left/right 0.25 rem => we need to adapt the width in order to make it same as configurator-target-manual-tariff*/
  float: right;
  padding-right: 0.3rem;
}

label.manual-tariff-button {
  margin-right: 0.5rem;
}

div.yes-no-switchbutton {
  width: 5rem;
}

div.attachment {
  margin-top: 5px;
  border: lightgray 1px solid;
  padding: 10px;
  border-radius: 5px;
  width: 160px;
  float: left;
  margin-right: 5px;
  display: flex;                   /* defines flexbox */
  flex-direction: column;          /* top to bottom */
  justify-content: space-between;  /* first item at start, last at end */
}

div.attachment button {
  margin-right: 10px;
}

div.attachment.deleted {
  text-decoration: line-through;
}

div.attachment figure {
  margin-bottom: 0px;
}

div.attachment figcaption {
  margin-top: 5px
}

div.attachment .filename {
  width: 140px;
  font-size: smaller;
  color: gray;
}


.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color:gray;
}

.link-button:hover {
  color:black;
}

.cursor-pointer{
  cursor: pointer;
}

div.create-policy-footer {
  background-color: rgba(0, 0, 0, 0.03);
}

.info-panel {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 25%;
  height: 400px;
  z-index: 9999;
}

.info-panel .info-panel-content {
  overflow-y: scroll;
}

.react-bootstrap-table .row-expansion-style {
  padding: 0px !important;
  border-bottom: 2px gray solid
}

.policy-versions-items {
  width: calc(100% + 2px);
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: 0px;
}

div.configurator-node-order-list-footer {
  position: fixed;
  right: 0;
  left: auto;
  bottom: 2em;
  z-index: 1030;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
}

a.nav-link.active-menu-item span {
  text-decoration: underline;
  color: blue;
}

a.nav-link.active-menu-item svg {
  color: blue;
}


.line-through {
  text-decoration: line-through;
}

.changelog-item-state.todo {
  background-color: #E30000 !important;
}

.changelog-item-state.in-progress {
  background-color: #A6E0FC !important;
}

.changelog-item-state.to-be-alpha-tested {
  background-color: #42A3DF !important;
}

.changelog-item-state.to-be-merged {
  background-color: #0070E4 !important;
}

.changelog-item-state.to-be-beta-tested {
  background-color: #B7E281 !important;
}

.changelog-item-state.resolved {
  background-color: #409600 !important;
}

.accounting-document-table {
  background-color: #eaeaea;
}

.accounting-record-table {
  background-color: #cbcbcb;
}

.accounting-openpost-table {
  background-color: #b9b9b9;
}

.node-caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid transparent;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .node-caret, .navbar-fixed-bottom .dropdown .node-caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid;
  content: "";
}

div.unpaid-invoice > a {
  font-size: small;
}

span.inactive-node {
  text-decoration: line-through;
  color: lightgray;
}

div.policy-target-condition-operator {
  width: 5rem;
}

input.policy-target-condition-name {
  border: 0;
  outline: 0;
  background: transparent;
  border: none;
  font-weight: bold;
  width: 100%;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.menu-line {
  border-left: 1px solid #e4e4e4;
}

@keyframes pop {
  from {
    box-shadow: 0px 0px #efefef;
  }
  to {
    transform: translate(-4px, -4px);
    box-shadow: 4px 4px #efefef;
  }
}

.menu-item:hover {
  animation-name: pop;
  animation-duration: 0.05s;
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
}

.appvers-style {
  border: solid 1px #cccccc;
  color: #ef3340;
  background: linear-gradient(0deg, rgba(200,200,200,1) 0%, rgba(200,200,200,1) 2%, rgba(222,222,222,1) 15%, rgba(222,222,222,1) 85%, rgba(255,255,255,1) 98%, rgba(255,255,255,1) 100%);
}

.btn-home {
  border-radius: 0.375rem;
  width: 40px !important;
  height: 40px;
}

.btn-home:hover {
  color: #ef3340;
}


.menu-item {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  display: inline-block;
}

.vert-horz-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.snooze-clock:hover {
  opacity: 0.75;
}

.snooze-clock {
  color: black;
  opacity: 0.5;
}

.fading-div {
  display: block;
  height: 200px;
  overflow: hidden;
  position: relative;
  mix-blend-mode: hard-light;
}

.fading-div:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px; /* Adjust this value to control the fade height */
  background: linear-gradient(transparent, gray);
  pointer-events: none;
}

td.cell-print-with-settlement-batch {
    background-color: var(--bs-info-bg-subtle);
}

.x-small {
  font-size: x-small;
}

.ticket-section-label {
    font-weight: bold;
}